import styled from "styled-components"
import media from "styled-media-query"
import { colors } from "../../styles/colors"

export const PanelWrapper = styled.section`
  position: relative;
  height: 90vh;

  ${media.lessThan("medium")`
    height: 81.5vh;
  `}
`

export const ImageWrapper = styled.div`
  div.gatsby-image-wrapper {
    height: 90vh;

    ${media.lessThan("medium")`
      height: 81.5vh;
    `}
  }
`

export const PanelShadow = styled.div`
  top: 0;
  left: 0;
  background: #000;
  height: 100%;
  width: 100%;
  opacity: 60%;
  position: absolute;
`

export const PanelContent = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  flex-wrap: wrap;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;
`

export const PanelSlogan = styled.h2`
  font-size: 1.75rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
  z-index: 1;
  max-width: 34.75rem;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-grow: 1;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 2;

  ${media.lessThan("1227px")`
    margin-top: 1rem;
  `}

  ${media.lessThan("small")`
    margin-top: 0.5rem;
  `}
`

export const ContactContainer = styled.div`
  display: flex;
  flex-grow: 1;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 2;

  ${media.lessThan("1227px")`
    margin-top: 1rem;
  `}

  ${media.lessThan("small")`
    margin-top: 0.5rem;
  `}
`

export const Slogan = styled.h2`
  max-width: 40rem;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
  z-index: 2;
`

export const OptionContainer = styled.li`
  display: flex;
  padding: 0.5rem;
  align-items: center;
`

export const Options = styled.ul`
  margin-bottom: 2.5rem;
`

export const OptionTitle = styled.h2`
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  color: ${colors.mainFont};
  text-align: center;
  margin-bottom: 2.5rem;
  max-width: 25rem;
`

export const OptionItem = styled.span`
  background-size: 32px 32px;
  font-size: 18px;
  color: ${colors.mainFont};
  margin-left: 1rem;
`
