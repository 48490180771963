import React from "react"

import SEO from "../components/seo"
import OpeningCompany from "../components/OpeningCompanyContent"

import Layout from "../components/Layout"

const AberturaDeEmpresa = () => (
  <Layout>
    <SEO title="Abertura de Empresa" />
    <OpeningCompany />
  </Layout>
)

export default AberturaDeEmpresa
