import React from "react"

import { openingCompany } from "./content"

import Contact from "../Contact"
import Image from "../Image"

import { CheckCircle } from "@styled-icons/fa-solid/CheckCircle";
import {
  PanelWrapper,
  PanelSlogan,
  PanelShadow,
  PanelContent,
  ImageWrapper,
  InnerContainer,
} from "./styles"
import {OptionTitle, OptionContainer, OptionItem, Options} from "../ContactPage/styles";
import {colors} from "../../styles/colors";

export default function OpeningCompanyContent() {
  const { title, contentTitle, optionTitle, options, contactTitle } = openingCompany

  return (
    <PanelWrapper>
      <ImageWrapper>
        <Image filename="abertura.png"/>
      </ImageWrapper>
      <PanelContent>
        <InnerContainer>
          <PanelSlogan>{title}</PanelSlogan>
          <Contact name="opening-company" />
        </InnerContainer>
        <InnerContainer>
          <OptionTitle>{optionTitle}</OptionTitle>
          <Options>
            {options.map((item, idx) => (
                <OptionContainer key={idx}>
                  <CheckCircle color={colors.checkColor} size={25} />
                  <OptionItem>{item}</OptionItem>
                </OptionContainer>
            ))}
          </Options>
        </InnerContainer>
      </PanelContent>
      <PanelShadow />
    </PanelWrapper>
  )
}

