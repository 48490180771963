export const openingCompany = {
  title: "Relacionamento de profissional para profissional",
  contentTitle: "Abertura de Empresa",
  optionTitle: "Acompanhamento pessoal",
  options: [
    "Acompanhamento Individualizado",
    "Análise da Atividade",
    "Elaboração de Contrato",
  ],
  contactTitle: "A sua Personal Accountant",
}
